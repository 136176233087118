<template>
  <div id="toolbarDiv" class="esri-component esri-widget">
    <button
      id="distance"
      @click="distanceMeasurement"
      class="esri-widget--button esri-interactive esri-icon-measure-line"
      title="测量长度"
    ></button>
    <button
      id="area"
      @click="areaMeasurement"
      class="esri-widget--button esri-interactive esri-icon-measure-area"
      title="测量面积"
    ></button>
    <button
      id="clear"
      @click="clearMeasurements"
      class="esri-widget--button esri-interactive esri-icon-trash"
      title="清除"
    ></button>
  </div>
</template>
<script>
export default {
  methods: {
    distanceMeasurement() {
      const distanceButton = document.getElementById("distance");
      const areaButton = document.getElementById("area");
      distanceButton.classList.add("active");
      areaButton.classList.remove("active");
      this.$emit("distanceMeasurement");
    },
    // Call the appropriate AreaMeasurement2D or AreaMeasurement3D
    areaMeasurement() {
      const distanceButton = document.getElementById("distance");
      const areaButton = document.getElementById("area");
      distanceButton.classList.remove("active");
      areaButton.classList.add("active");
      this.$emit("areaMeasurement");
    },
    // Clears all measurementsinfoDiv
    clearMeasurements() {
      const distanceButton = document.getElementById("distance");
      const areaButton = document.getElementById("area");
      distanceButton.classList.remove("active");
      areaButton.classList.remove("active");
      this.$emit("clearMeasurements");
    },
  },
};
</script>
<style scoped>
#toolbarDiv {
  position: absolute;
  top: 55px;
  right: 15px;
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.esri-widget--button.active,
.esri-widget--button.active:hover,
.esri-widget--button.active:focus {
  cursor: default;
  background-color: #999696;
}
.esri-widget--button.active path,
.esri-widget--button.active:hover path,
.esri-widget--button.active:focus path {
  fill: #e4e4e4;
}
</style>
