import { render, staticRenderFns } from "./LayersShow.vue?vue&type=template&id=36e0670b&scoped=true&"
import script from "./LayersShow.vue?vue&type=script&lang=ts&"
export * from "./LayersShow.vue?vue&type=script&lang=ts&"
import style0 from "./LayersShow.vue?vue&type=style&index=0&id=36e0670b&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e0670b",
  null
  
)

export default component.exports