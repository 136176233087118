<template>
  <div class="pop">
    <div class="nav">
      <span>坐标定位</span>
      <div class="back" @click="close">
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="main">
      <div class="x_value">
        <span>经度坐标:</span>
        <input
          type="text"
          v-model="coordinate.x"
          placeholder="请输入经度坐标"
        />
      </div>
      <div class="y_value">
        <span>纬度坐标:</span>
        <input
          type="text"
          v-model="coordinate.y"
          placeholder="请输入纬度坐标"
        />
      </div>
    </div>
    <div class="fotter">
      <span @click="position">确定</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      coordinate: {},
    };
  },
  methods: {
    position() {
      this.$emit("position", this.coordinate.x, this.coordinate.y);
      this.coordinate = {};
    },
    close() {
      this.$parent.coordinateShow = false;
    },
  },
  props: ["back"],
};
</script>
<style  lang="less" scoped>
.pop {
  position: absolute;
  top: 60px;
  right: 2%;
  width: 300px;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
  opacity: 0.8;
  border-radius: 2px;

  .nav {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .back {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        position: absolute;
        width: 2px;
        height: 16px;
        transform: rotate(45deg);
        background: rgba(0, 0, 0, 1);
      }

      span:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  .main {
    width: 100%;
    height: 50%;
    padding: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);

    .x_value {
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        margin-left: 10px;
      }
    }

    .y_value {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        margin-left: 10px;
      }
    }
  }

  .fotter {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    span {
      padding: 5px;
      width: 65%;
      background: linear-gradient(
        -90deg,
        rgba(0, 123, 255, 1),
        rgba(0, 123, 255, 1)
      );
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>