<template>
  <div class="pile_number">
    <div class="nav">
      <span>桩号定位</span>
      <div class="back" @click="close">
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="main">
      <div>
        <span>路线编码:</span>
        <input type="text" v-model="pileData.LXBM" />
      </div>
      <div>
        <span>路线方向:</span>
        <select v-model="pileData.Lxfxdm">
          <option value="1">上行</option>
          <option value="2">下行</option>
        </select>
      </div>
      <div>
        <span>定位桩号:</span>
        <input type="text" v-model="pileData.MValue" />
      </div>
      <div>
        <span>坐标位置:</span>
        <input readonly="true" type="text" v-model="pileResultData" />
      </div>
    </div>
    <div class="fotter">
      <span @click.self="Pilepoint">确定</span>
    </div>
  </div>
</template>
<script>
import { debounce } from "@/plugins/common.js";
import { retrieveRoutePointGeometryUrl, QueryUrl } from "../../map/url.js";
export default {
  data() {
    return {
      //桩号定位数据
      pileData: {
        LXBM: null, //路线编码
        MValue: null, //桩号坐标
        Lxfxdm: 1, //路线方向代码（1：上，2：下）
      },
      pileResultData: null, //桩号定位查询结果xy坐标
      img3: require("@/assets/mapimages/new/infobox-close.png"),
    };
  },
  mounted() {},
  methods: {
    loadingState(state) {
      this.$parent.loadingState(state);
    },
    //桩号定位
    Pilepoint: debounce(function () {
      this.loadingState(true);
      this.$parent.clearScreen();
      let formData = { ...this.pileData };
      if (formData.LXBM) {
        formData.LXBM = formData.LXBM.toUpperCase();
      }
      let layerUrl = null;
      if (typeof formData.LXBM == "string" && formData.LXBM.substring(0, 1)) {
        this.$PileTable.forEach((v) => {
          if (v.value == formData.LXBM.substring(0, 1).toUpperCase()) {
            formData.layerIndex = v.index;
            layerUrl = QueryUrl + v.layerId;
            return false;
          }
        });
      }
      formData.Nd = this.$nd;
      formData.f = "pjson";
      this.$http({
        methods: "get",
        url: retrieveRoutePointGeometryUrl,
        params: {
          LXBM: formData.LXBM,
          MValue: "M" + formData.MValue,
          layerIndex: "M" + formData.layerIndex,
          Lxfxdm: "_" + formData.Lxfxdm,
          Nd: "_" + formData.Nd,
          f: formData.f,
          token: this.token,
        },
      })
        .then((res) => {
          if (res.data != {}) {
            this.pileX = res.data.x;
            this.pileY = res.data.y;
            this.pileQuery(formData.LXBM, layerUrl);

            var point = {
              geometry: {
                type: "point",
                x: res.data.x,
                y: res.data.y,
                spatialReference: { wkid: 3857 },
              },
            };
            var graphic = this.$parent.webMercatorUtils.webMercatorToGeographic(
              point.geometry
            );
            this.pileResultData = `${graphic.x.toFixed(
              5
            )} , ${graphic.y.toFixed(5)}`;
          } else {
            this.$toastr("输入的参数不正确");
            this.loadingState(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 300),
    //查信息
    pileQuery(LXBM, layerUrl) {
      this.$parent.activeView.graphics.removeAll();
      this.where = "LXBM  = '" + LXBM + "'";
      var queryTask = new this.$parent.QueryTask(layerUrl);
      var query = new this.$parent.Query();
      query.where = this.where;
      query.outSpatialReference = { wkid: 3857 };
      query.returnGeometry = true;
      query.outFields = ["*"];
      queryTask.execute(query).then(this.pileQueryPop);
    },
    //桩号定位弹出框
    pileQueryPop(res) {
      let graphic = res.features[0];
      var x = this.pileX;
      var y = this.pileY;

      var view = this.$parent.activeView;
      let point = {
        type: "point", // autocasts as new Point()
        x: x,
        y: y,
        spatialReference: { wkid: 3857 },
      };

      let pointGraphic = new this.$parent.Graphic({
        geometry: point,
      });

      //信息框弹出
      pointGraphic.pop = {
        x: x,
        y: y,
      };

      //渲染动画
      pointGraphic.attr = {
        x: x,
        y: y,
      };

      view.graphics.add(pointGraphic);

      let title = this.createTitle(graphic);

      this.$emit("poptemplate", title);
      this.$emit("animation");
      this.loadingState(false);

      view.goTo({
        target: pointGraphic.geometry,
        zoom: 13,
        speedFactor: 6,
        easing: "linear",
      });
    },
    createTitle(graphic) {
      var title = "";
      title = `<div style='height:100%; width:100%;' class='popup_select' >
                          <div class="header">
                           <div class="hang">${graphic.attributes["LXMC"]}</div>
                               <i  onclick="closePop()" >
                                <img src="${this.img3}" alt="">
                                </i>
                           </div>
                           <div class="center">
                               <div class="hang">
                               <span>路线编码 : </span>
                               <span>${graphic.attributes["LXBM"]} </span>
                               </div>
                               <div class="hang">
                               <span>技术等级 : </span>
                               <span>${graphic.attributes["Ldjsdj"]} </span>
                               </div>
                               <div class="hang">
                               <span>路面类型 : </span>
                               <span>${graphic.attributes["Ldlmlx"]} </span>
                               </div>
                               <div class="hang">
                               <span>路线方向 :</span>
                               <span>${
                                 this.pileData.Lxfxdm == 1 ? "上行" : "下行"
                               } </span>
                               </div>
                               <div class="hang">
                               <span>桩号位置 : </span>
                               <span>${this.pileData.MValue} </span>
                               </div>
                           </div>
                          </div>`;
      return title;
    },
    close() {
      this.$parent.PilePointShow = false;
    },
  },
  props: ["back", "token"],
};
</script>
<style lang="less" scoped>
.pile_number {
  position: absolute;
  top: 60px;
  right: 40px;
  width: 300px;
  height: 180px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
  opacity: 0.8;
  border-radius: 2px;

  .nav {
    // width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
    opacity: 0.8;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .back {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 0px;

      span {
        position: absolute;
        width: 2px;
        height: 16px;
        transform: rotate(45deg);
        background: rgba(0, 0, 0, 1);
      }

      span:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  .main {
    // width: 100%;
    height: 120px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
    opacity: 0.8;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);

    input,
    select {
      width: 190px !important;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-right: 0px;
        width: 70px;
      }
    }
  }

  .fotter {
    width: 100%;
    height: 50px;
    display: flex;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
    opacity: 0.8;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    span {
      padding: 5px;
      width: 65%;
      background: linear-gradient(
        -90deg,
        rgba(0, 123, 255, 1),
        rgba(0, 123, 255, 1)
      );
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>