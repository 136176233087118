<template>
  <div>
    <div id="layerCheckall">
      <input type="checkbox" @click="allLayer" :checked="showlayer" />
      <span class="span_width">全选/反选</span>
    </div>
    <ul>
      <li v-for="item in layerArray" :key="item.id">
        <div class="checkbox">
          <input
            :checked="item.visible"
            type="checkbox"
            @click="achangeLayer(item)"
          />
          <span class="span_width">{{ item.name }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { arrDistinctByProp, compare } from "../../map/methods";
export default {
  mounted() {
    this.layerArray = arrDistinctByProp(this.layers, "name");
    this.layerArray = this.layerArray.sort(compare);
  },
  data() {
    return {
      showlayer: true,
      layerArray: null,
      layers: [
        { index: 7, name: "村道", title: "村道断头路", id: 36, visible: true },
        { index: 7, name: "村道", title: "村道城管路", id: 35, visible: true },
        { index: 7, name: "村道", title: "村道", id: 34, visible: true },
        {
          index: 6,
          name: "专用公路",
          title: "专用公路断头路",
          id: 33,
          visible: true,
        },
        {
          index: 6,
          name: "专用公路",
          title: "专用公路城管路",
          id: 32,
          visible: true,
        },
        {
          index: 6,
          name: "专用公路",
          title: "专用公路",
          id: 31,
          visible: true,
        },
        { index: 5, name: "乡道", title: "乡道断头路", id: 30, visible: true },
        { index: 5, name: "乡道", title: "乡道城管路", id: 29, visible: true },
        { index: 5, name: "乡道", title: "乡道", id: 28, visible: true },
        { index: 4, name: "县道", title: "县道断头路", id: 27, visible: true },
        { index: 4, name: "县道", title: "县道城管路", id: 26, visible: true },
        { index: 4, name: "县道", title: "县道", id: 25, visible: true },
        { index: 3, name: "省道", title: "省道断头路", id: 24, visible: true },
        { index: 3, name: "省道", title: "省道城管路", id: 23, visible: true },
        {
          index: 2,
          name: "省道高速",
          title: "省道高速",
          id: 22,
          visible: true,
        },
        { index: 3, name: "省道", title: "省道", id: 21, visible: true },
        { index: 1, name: "国道", title: "规划线", id: 20, visible: true },
        { index: 1, name: "国道", title: "国道断头路", id: 19, visible: true },
        { index: 1, name: "国道", title: "国道城管路", id: 18, visible: true },
        { index: 1, name: "国道", title: "国道", id: 17, visible: true },
        { index: 15, name: "匝道", title: "匝道", id: 16, visible: true },
        { index: 14, name: "自然村", title: "自然村", id: 15, visible: true },
        { index: 13, name: "建制村", title: "建制村", id: 14, visible: true },
        { index: 12, name: "乡镇", title: "乡镇", id: 13, visible: true },
        { index: 11, name: "涵洞", title: "涵洞", id: 12, visible: true },
        { index: 10, name: "渡口", title: "渡口", id: 11, visible: true },
        { index: 9, name: "隧道", title: "隧道", id: 10, visible: true },
        { index: 8, name: "桥梁", title: "桥梁", id: 9, visible: true },
        {
          index: 23,
          name: "超限监测站",
          title: "超限监测站",
          id: 8,
          visible: true,
        },
        { index: 16, name: "服务区", title: "服务区", id: 7, visible: true },
        { index: 17, name: "加油站", title: "加油站", id: 6, visible: true },
        { index: 18, name: "收费站", title: "收费站", id: 5, visible: true },
        {
          index: 19,
          name: "养护中心",
          title: "养护中心",
          id: 4,
          visible: true,
        },
        {
          index: 20,
          name: "交叉道口",
          title: "交叉道口",
          id: 3,
          visible: true,
        },
        {
          index: 21,
          name: "交调观测站",
          title: "交调观测站",
          id: 2,
          visible: true,
        },
        { index: 22, name: "门架", title: "门架", id: 1, visible: true },
        {
          index: 24,
          name: "标志标牌",
          title: "标志标牌",
          id: 0,
          visible: true,
        },
      ],
    };
  },
  methods: {
    achangeLayer(item) {
      item.visible = !item.visible;
      var selLayers = this.layers.filter((v) => v.name == item.name);
      selLayers.forEach((element) => {
        element.visible = item.visible;
      });
      this.$parent.achangeLayer(selLayers);
    },
    allLayer() {
      this.showlayer = !this.showlayer;
      this.layers.forEach((element) => {
        element.visible = this.showlayer;
      });

      this.$parent.achangeLayer(this.layers);
    },
  },
};
</script>

<style scoped lang='less'>
#layerCheckall {
  margin-left: 6px;
  font-weight: bolder;
}
ul {
  margin-top: 5px;
  height: 60vh;
  width: 245px;
  overflow-y: scroll;

  li {
    display: flex;
    color: #000;
    font-size: 14px;
    padding: 4px 6px;
  }
}
.span_width {
  margin-left: 5px;
}
</style>

